import React, { createContext, useContext, useEffect, useReducer } from 'react';

export const constRequestContext = {
    CUSTOMER_NAME: 'CustomerName',
    CONVENTION_TYPE_NAME: 'ConventionTypeName',
    SOURCE_NAME: 'SourceName',
    WARRANTY_REQUEST_ID: 'WarrantyRequestId',
    CUSTOMER_TYPE_ID: 'CustomerTypeId',
    STATUS_ID: 'StatusId',
    PAGE_ID: 'PageId',
    ADDITIONAL_DATA: 'AditionalData',
    CONVENTION_TYPE_ID: 'ConventionTypeId',
    WARRANTY_REQUEST_NAME: 'WarrantyRequestName',
    ANALYST: 'Analyst',
    STATUS_NAME: 'StatusName',
    UNIQUE_GUARANTEE_CODE: 'UniqueGuaranteeCode',
    STATUS_SAVE_TABS: 'StatusSaveTabs',
    ADD_REQUEST: 'AddRequest',
};

export const actionRequestConst = {
    SET_ALL_DATA: 'SET_ALL_DATA',
    RESET: 'RESET',
    UPDATE_SINGLE_FIELD: 'UPDATE_SINGLE_FIELD',
};

const initialState = {
    CustomerName: '',
    ConventionTypeName: '',
    SourceName: '',
    WarrantyRequestId: null,
    CustomerTypeId: null,
    StatusId: null,
    PageId: null,
    AditionalData: [],
    ConventionTypeId: null,
    WarrantyRequestName: '',
    Analyst: '',
    StatusName: '',
    UniqueGuaranteeCode: '',
    StatusSaveTabs: {},
    AddRequest: false,
};

function dataReducer(state, action) {
    switch (action.type) {
        case actionRequestConst.SET_ALL_DATA:
            return { ...state, ...action.payload };
        case actionRequestConst.RESET:
            return initialState;
        case actionRequestConst.UPDATE_SINGLE_FIELD:
            return { ...state, [action.field]: action.value };
        default:
            console.warn(`Acțiune necunoscută: ${action.type}`);
            return state;
    }
}

const DataContext = createContext();

export const DataRequestProvider = ({ children }) => {
    const persistedState = JSON.parse(localStorage.getItem('requestData')) || initialState;
    const [state, dispatch] = useReducer(dataReducer, persistedState);

    useEffect(() => {
        localStorage.setItem('requestData', JSON.stringify(state));
    }, [state]);

    return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

export const useRequestData = () => useContext(DataContext);
