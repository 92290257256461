import React from 'react';

import { LoadPanel } from 'devextreme-react/load-panel';
import { loadMessages, locale } from 'devextreme/localization';
import roMessages from 'devextreme/localization/messages/ro.json';
import { useAuth } from './contexts/auth';
import { NotificationProvider } from './contexts/counter';
import { StoreDataProvider } from './contexts/dataContext';
import { DataRequestProvider } from './contexts/requestCtx';
import { createLoadableComponent } from './lodableHelper';
const UnauthenticatedContent = createLoadableComponent(() => import('./UnauthenticatedContent'));
const Content = createLoadableComponent(() => import('./Content'));

function App() {
    const { user, loading } = useAuth();

    locale('ro');
    loadMessages(roMessages);

    if (loading) {
        return <LoadPanel visible={true} />;
    }

    if (user) {
        return (
            <DataRequestProvider>
                <StoreDataProvider>
                    <NotificationProvider>
                        <Content />
                    </NotificationProvider>
                </StoreDataProvider>
            </DataRequestProvider>
        );
    } else {
        return <UnauthenticatedContent />;
    }
}

export default React.memo(App);
