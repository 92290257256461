import jwtDecode from 'jwt-decode';
class PermissionsService {
    userHasPermission(permission) {
        let token = localStorage.getItem('access_token');
        if (!token) {
            return false;
        }

        let claims = jwtDecode(token);

        return String(claims['rights']).split(',').indexOf(permission) >= 0;
    }
}

const Permissions = new PermissionsService();

export const canDoAction = (key) => Permissions.userHasPermission(key);

export default Permissions;
