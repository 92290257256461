import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import './themes/custom-theme/dx.generic.custom-theme-fgcr.3.2024.css';
import './themes/custom-theme/custom-theme-changes.scss';

import config from 'devextreme/core/config';
import App from './App';
import { AuthProvider } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import { useScreenSizeClass } from './utils/media-query';

const licenseKey = process.env.REACT_APP_DEVEXTREME_KEY;

config({
    licenseKey,
});

export default React.memo(function Root() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
            <AuthProvider>
                <NavigationProvider>
                    <div className={`app ${screenSizeClass}`}>
                        <App />
                    </div>
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
});
